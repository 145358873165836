import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  TextField,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { AddCircleOutline, Clear, ExpandMore } from "@mui/icons-material";
import {
  calComparedDate,
  checkDate,
  DateDisplayFormatter,
  DisplayDateForPayload,
} from "../Charts/ChartUtils";
import React, { useContext, useEffect, useState } from "react";
import FilterPopup from "../Filteration/filterpopup/FilterPopup";
import MetricList from "./MetricList";
import { Filter, Metric } from "../../models/chart";
import { useSelector } from "react-redux";
import { ReactComponent as FilterIcon } from "../../Assets/Icons/openFilter.svg";
import FilterListModal from "../Filteration/FilterListModal";
import { useDispatch } from "react-redux";
import { updateFilterChartMap } from "../../api/dashboard/action";
import { DimensionsLength, MetricsLength } from "../Charts/ChartUtils";
import DateRangeModal from "../Modal/DateRangeModal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ReactComponent as CalendarIcon } from "../../Assets/Icons/boxless-calendar.svg";
import "./editwidget.css"
import { transformFromFilterDataObject } from "../Filteration/Utils";
import FilterDrop from "./FilterDrop";
import { ShareContext } from "./Sidebar";

export default function EditChartType3({
  handleAllChartsForceReload,
  editDrawerState,
  currentChartType,
  setSwitchChartDimensions,
  setSwitchChartMetrics,
  channel,
  accountCols,
  channelCols,
}) {
  const { shared } = useContext(ShareContext);
  const { chart, handleChartChange } = editDrawerState;
  const [chartDimensions, setChartDimensions] = React.useState(
    !DimensionsLength[currentChartType] ||
      chart.dimensions.length < DimensionsLength[currentChartType]
      ? chart.dimensions?.concat([null])
      : chart.dimensions
  );
  const [chartMetrics, setChartMetrics] = React.useState(
    !MetricsLength[currentChartType] ||
      chart.leftMetrics.length < MetricsLength[currentChartType]
      ? chart.leftMetrics.concat([null])
      : chart.leftMetrics
  );

  const [filterListOpen, setFilterListOpen] = React.useState(false);
  const [isFilterOpen, setIsFilterOpen] = React.useState(false);
  const [filterString, setFilterString] = React.useState(
    chart?.filter?.toString()
  );
  const [chartFilter, setChartFilter] = React.useState(chart.filter);
  const [currentFilter, setCurrentFilter] = React.useState(null);
  const [table, setTable] = React.useState(chart.table);

  const [anchorEl, setAnchorEl] = useState(null);

  const [dataLevel, setDataLevel] = React.useState({
    id: chart.dataLevel,
    name: chart.dataLevel,
  });
  const state = useSelector((state) => channel.getState(state));
  const tables = state.tables;
  const dataLevels = (state.dataLevels?.[table?.id] ?? []).map((level) => ({
    id: level,
    name: level,
  }));

  const baseMetrics = channel.isBlend
    ? channelCols.metrics
    : Array.isArray(state.metrics)
      ? state.metrics
      : state.metrics?.[table?.id + (dataLevel?.id ? `:${dataLevel.id}` : "")] ??
      [];
  const baseDimensions = channel.isBlend
    ? channelCols.dimensions
    : Array.isArray(state.dimensions)
      ? state.dimensions
      : state.dimensions?.[
      table?.id + (dataLevel?.id ? `:${dataLevel.id}` : "")
      ] ?? [];

  const customMetrics = accountCols
    ? accountCols.metrics
    : Array.isArray(state.customMetrics)
      ? state.customMetrics
      : state.customMetrics?.[
      table?.id + (dataLevel?.id ? `:${dataLevel.id}` : "")
      ] ?? [];
  const customDimensions = accountCols
    ? accountCols.dimensions
    : Array.isArray(state.customDimensions)
      ? state.customDimensions
      : state.customDimensions?.[
      table?.id + (dataLevel?.id ? `:${dataLevel.id}` : "")
      ] ?? [];

  const metrics = baseMetrics.concat(customMetrics);
  const dimensions = baseDimensions.concat(customDimensions);

  const dispatch = useDispatch();
  const _updateFilterChartMap = (_) => dispatch(updateFilterChartMap(_));
  const filterChartMap = useSelector((state) => state.dashboard.filterChartMap);

  const dashboardDateRange = useSelector(state => state.dashboard.dateRange);
  const [compareWith, setCompareWith] = React.useState(chart.compareWith);
  const [dateRange, setDateRange] = React.useState(chart.compareWith?.startsWith("Previous")
    ? calComparedDate(chart.compareWith, dashboardDateRange)
    : {
      label: "Custom date range",
      startDate: chart.compareWith?.split("_")[0],
      endDate: chart.compareWith?.split("_")[1],
    }
  );
  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false);

  const applyDate = (datePickerRange) => {
    setDateRange(datePickerRange);
    setIsDatePickerOpen(false);
    if (datePickerRange.startDate && datePickerRange.endDate) {
      handleChartChange(
        "compareWith",
        datePickerRange.label === "Custom date range"
          ? (DisplayDateForPayload(datePickerRange?.startDate) + "_" + DisplayDateForPayload(datePickerRange?.endDate))
          : datePickerRange.label
      );
    } else {
      setCompareWith("");
      handleChartChange("compareWith", "");
    }
  };

  // Custom sorting hierarchy for "day," "week," "month," and "year"
  const customSortOrder = [
    "Year", "Quarter", "Month of year", "Month",
    "Week of Year", "Week", "Day of week",
    "Group by day", "Day", "Date",
    "Hour", "Hour of day", "Minute"
  ]

  function customSort(a, b) {
    if (!a || !b) return 0;

    const indexA = customSortOrder.findIndex(val => a.name === val || a.name.split(" ")[0] === val);
    const indexB = customSortOrder.findIndex(val => b.name === val || b.name.split(" ")[0] === val);

    if (indexA !== -1 && indexB !== -1) return indexA - indexB;
    if (indexA !== -1) return -1;
    if (indexB !== -1) return 1;

    return 0;
  }

  const isDateAvailable = (dimensions) => {
    return dimensions.some((d) => checkDate(d));
  };
  const [dateAvailable, setDateAvailable] = React.useState(
    isDateAvailable(chartDimensions)
  );

  const updateFilter = (filter) => {
    setFilterString(filter.toString());
    handleChartChange("filter", filter);
    setChartFilter(filter);
    setCurrentFilter(null);
    if (!filter.id) return;
    let allCharts = filterChartMap[filter.id] ?? [];
    if (!allCharts.includes(chart.id)) {
      _updateFilterChartMap({
        ...filterChartMap,
        [filter.id]: (filterChartMap[filter.id] ?? []).concat(chart.id),
      });
    }
  };

  React.useEffect(() => {
    if (table !== chart.table) {
      handleChartChange("table", table);
      {
        channel.type === "tiktok-ads" &&
          setDataLevel(dataLevels[table?.id]?.[0] ?? { id: "", name: "" });
      }
      setChartMetrics([null]);
      setChartDimensions([null]);
      // if (!metrics.length) {
      //   dispatch(channel.actions?.getColumns(table));
      // }
    }
  }, [table]);

  React.useEffect(() => {
    handleChartChange(
      "dimensions",
      chartDimensions.filter((dimension) => dimension && dimension.id)
    );
    setSwitchChartDimensions(chartDimensions);
  }, [chartDimensions]);

  React.useEffect(() => {
    if (
      MetricsLength[currentChartType] &&
      chartMetrics.length < MetricsLength[currentChartType]
    ) {
      setChartMetrics(chartMetrics.concat([null]));
    } else {
      handleChartChange(
        "leftMetrics",
        chartMetrics.filter((metric) => metric && metric.metric)
      );
      setSwitchChartMetrics(chartMetrics);
    }
  }, [chartMetrics]);

  React.useEffect(() => {
    if (chart.dataLevel !== dataLevel.id) {
      handleChartChange("dataLevel", dataLevel?.id);
      setChartMetrics([null]);
      setChartDimensions([null]);
    }
  }, [dataLevel]);

  React.useEffect(() => {
    // setChartMetrics(chartMetrics.filter(metric => !metric || metrics?.findIndex(m => m.id === metric?.metric?.id) > -1));
    // setChartDimensions(chartDimensions.filter(dim => !dim || dimensions?.findIndex(d => d.id === dim?.id) > -1));
  }, [accountCols])

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    destination.index =
      destination.index === chartMetrics.length - 1
        ? destination.index - 1
        : destination.index;
    if (destination.index === source.index) return;
    const newMetrics = Array.from(chartMetrics);
    const pop = newMetrics.splice(source.index, 1)[0];
    newMetrics.splice(destination.index, 0, pop);
    setChartMetrics(newMetrics);
  };

  const mappedCoordinatesBubble = new Map([
    [0, "X axis"],
    [1, "Y axis"],
    [2, "Size"],
  ]);
  return (
    <div>
      <Accordion
        defaultExpanded={chart.title === ""}
        sx={{
          boxShadow: "none",
          width: "100%",
          '&:before': {
            display: 'none',
          },
        }}
      >
        <AccordionSummary
          sx={{ maxHeight: "30px", width: "100%", padding: "0px" }}
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            style={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "500",
              color: "#000000",
            }}
          >
            Title
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "0px 0px" }}>
          <TextField
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #EAEAEC",
              },
              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                border: "1px solid #EAEAEC",
              },
              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
              {
                border: "1px solid #EAEAEC",
              },
              borderRadius: "8px",
            }}
            InputProps={{
              sx: {
                fontSize: "14px",
                fontFamily: "Inter",
                fontWeight: "500",
                height: "40px"
              },
            }}
            placeholder="Enter Title"
            style={{ width: "100%", color: "red" }}
            id="outlined-basic"
            defaultValue={chart.title}
            onChange={(e) => handleChartChange("title", e.target.value)}
            variant="outlined"
            size="small"
          />
        </AccordionDetails>
      </Accordion>

      {/* {currentChartType === "TABLE" && ( */}
      {/* <Accordion
        sx={{
          boxShadow: "none",
          width: "100%",
          '&:before': {
            display: 'none',
          },
        }}
        disableGutters
        elevation={0}
      >
        <AccordionSummary
          sx={{ maxHeight: "30px", width: "100%", padding: "0px" }}
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            style={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "500",
              color: "#00000",
            }}
          >
            Compare with
          </Typography>
        </AccordionSummary>
        {dateAvailable ? (
          <AccordionDetails style={{ padding: "10px 0px" }}>
            <Select
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #EAEAEC",
                },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #EAEAEC",
                },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #EAEAEC",
                }
              }}
              style={{ width: "100%" }}
              variant="outlined"
              size="small"
              value={compareWith}
              onChange={(e) => {
                setCompareWith(e.target.value);
                handleChartChange("compareWith", e.target.value);
              }}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">
                <em>No Comparison</em>
              </MenuItem>
              <MenuItem value="day">Day</MenuItem>
              <MenuItem value="week">Week</MenuItem>
              <MenuItem value="months">Month</MenuItem>
            </Select>
          </AccordionDetails>
        ) : (
          <AccordionDetails style={{ padding: "10px 0px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #EAEAEC",
                width: "100%",
                borderRadius: "6px",
                padding: "0px 16px",
                cursor: "pointer",
                height: "40px"
              }}
              onClick={() => setIsDatePickerOpen(true)}
            >
              <IconButton
                disableRipple
                onClick={() => setIsDatePickerOpen(true)}
                aria-label="delete"
              >
                <CalendarIcon />
              </IconButton>
              <Typography
                variant="h6"
                component="div"
                style={{ lineHeight: "16px", marginLeft: "10px" }}
              >
                {/* <span className="inter" style={{ color: "#858585", fontSize: 10 }}>
                  {dateRange?.label ?? (dateRange?.startDate && dateRange?.endDate ? "Custom Date Range" : "")}
                </span>
                <br /> *}
                <span
                  className="inter"
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "20px",
                    color: "#585969",
                  }}
                >
                  {dateRange?.startDate && dateRange?.endDate
                    ? `${DateDisplayFormatter(dateRange?.startDate)} -${" "}
                                        ${DateDisplayFormatter(
                      dateRange?.endDate
                    )}`
                    : "No Comparison"}
                </span>
              </Typography>
            </div>
          </AccordionDetails>
        )}
      </Accordion> */}
      {/* )} */}

      <Accordion
        defaultExpanded={true}
        sx={{
          boxShadow: "none",
          width: "100%",
          '&:before': {
            display: 'none',
          }
        }}
        disableGutters
        elevation={0}
        id="editWigetScroll"
      >
        <AccordionSummary
          sx={{ maxHeight: "30px", width: "100%", padding: "0px" }}
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            style={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "500",
              color: "#00000",
            }}
          >
            Widget Data
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "10px 0px" }}>
          {/* report type */}
          {tables && (
            <div>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#666666",
                }}
              >
                Report type
              </Typography>
              <MetricList
                channel={channel}
                metric={table}
                metrics={tables}
                isEditable={true}
                onClick={setTable}
                defaultTitle="Add Report Type"
              />
              <br />
            </div>
          )}

          {tables && channel.type === "tiktok-ads" && (
            <div>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#666666",
                }}
              >
                Data level
              </Typography>
              <MetricList
                channel={channel}
                metric={dataLevel}
                metrics={dataLevels}
                isEditable={true}
                onClick={setDataLevel}
                defaultTitle="Add Data Level"
              />
              <br />
            </div>
          )}
          {/* dimensions */}

          <Typography
            style={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "500",
              color: "#666666",
            }}
          >
            Dimensions
          </Typography>
          {chartDimensions.map(
            (dimension, index) =>
              (!DimensionsLength[currentChartType] ||
                index < DimensionsLength[currentChartType]) && (
                <MetricList
                  key={chartDimensions.length * (index + 1)}
                  channel={channel}
                  metric={dimension}
                  isEditable={true}
                  defaultTitle="Add New Dimension"
                  metrics={
                    currentChartType === "GEO" && index === 0
                      ? dimensions.filter((dim) =>
                        channel.type !== "appsflyer" ? dim.name.toLowerCase().includes("country") : dim.name.toLowerCase().includes("geo")
                      )
                      : dimensions.filter(
                        (dim) =>
                          chartDimensions.findIndex(
                            (d) =>
                              d?.id + d?.dataSource + d?.audiences?.[0]?.id === dim.id + dim?.dataSource + dim?.audiences?.[0]?.id
                          ) === -1
                      )
                  }
                  onClick={(dimension) => {
                    const newDimensions = Array.from(chartDimensions);
                    newDimensions[index] = dimension;
                    newDimensions.sort(customSort);
                    // if (currentChartType === "TABLE" && checkDate(dimension)) {
                    //   newDimensions.splice(index, 1);
                    //   newDimensions.unshift(dimension);
                    // } else {
                    //   newDimensions[index] = dimension;
                    // }
                    if (
                      dimension &&
                      index === newDimensions.length - 1 &&
                      (!DimensionsLength[currentChartType] ||
                        chartDimensions.length <
                        DimensionsLength[currentChartType])
                    ) {
                      newDimensions.push(null);
                    }
                    setChartDimensions(newDimensions);
                    if (isDateAvailable(newDimensions) !== dateAvailable) {
                      applyDate({ startDate: "", endDate: "" });
                      setDateAvailable(!dateAvailable);
                    }
                  }}
                  onDelete={
                    chartDimensions.length <= 1
                      ? null
                      : () => {
                        const newDimensions = Array.from(chartDimensions);
                        if (
                          ["HEATMAP", "TREEMAP", "BUBBLE", "SCATTER", "GEO"].includes(
                            currentChartType
                          ) &&
                          chartDimensions.filter((d) => d).length ===
                          DimensionsLength[currentChartType]
                        ) {
                          newDimensions.push(null);
                        }
                        const slicedDimensions = newDimensions
                          .slice(0, index)
                          .concat(newDimensions.slice(index + 1));
                        setChartDimensions(slicedDimensions);
                        if (
                          isDateAvailable(slicedDimensions) !== dateAvailable
                        ) {
                          applyDate({ startDate: "", endDate: "" });
                          setDateAvailable(!dateAvailable);
                        }
                      }
                  }
                />
              )
          )}

          {/* metrics */}
          <Typography
            style={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "500",
              color: "#666666",
              marginTop: "15px",
            }}
          >
            Metrics
          </Typography>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="1" direction="vertical">
              {(droppableProvided) => (
                <div
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                >
                  {chartMetrics.map((metric, index) => {
                    return (
                      <Draggable
                        draggableId={`${index}`}
                        index={index}
                        disableInteractiveElementBlocking
                      >
                        {(draggableProvided) => (
                          <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                          >
                            <>
                              {currentChartType === "BUBBLE" && (
                                <h4
                                  className="inter bold"
                                  style={{ marginTop: "15px" }}
                                >
                                  {mappedCoordinatesBubble.get(index)}
                                </h4>
                              )}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "2px",
                                  //   marginLeft: metric?.metric ? "20px" : "0px",
                                }}
                              >
                                <MetricList
                                  key={chartMetrics.length * (index + 1)}
                                  channel={channel}
                                  metric={metric?.metric}
                                  isEditable={true}
                                  value={metric}
                                  metrics={metrics.filter(
                                    (metric) =>
                                      chartMetrics.findIndex(
                                        (m) =>
                                          (m?.metric?.id + m?.metric?.dataSource + m?.metric?.audiences?.[0]?.id) ===
                                          (metric.id + metric.dataSource + metric?.audiences?.[0]?.id)
                                      ) === -1
                                  )}
                                  onClick={(metric) => {
                                    const newMetrics = Array.from(chartMetrics);
                                    newMetrics[index] = Metric.new(
                                      chart,
                                      metric
                                    );
                                    if (
                                      metric &&
                                      index === newMetrics.length - 1 &&
                                      (!MetricsLength[currentChartType] ||
                                        chartMetrics.length <
                                        MetricsLength[currentChartType])
                                    ) {
                                      newMetrics.push(null);
                                    }
                                    setChartMetrics(newMetrics);
                                  }}
                                  draggableProvided={draggableProvided}
                                  onDelete={
                                    chartMetrics.length <= 1
                                      ? null
                                      : () => {
                                        const newMetrics =
                                          Array.from(chartMetrics);
                                        setChartMetrics(
                                          newMetrics
                                            .slice(0, index)
                                            .concat(
                                              newMetrics.slice(index + 1)
                                            )
                                        );
                                      }
                                  }
                                  isColumnThere={metric ? (metrics?.find(m => m.id === metric?.metric?.id) ?? false) : true}
                                />
                              </div>
                            </>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {droppableProvided ? droppableProvided.placeholder : null}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <>
            {/* filters */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "15px",
                width: "92%",
                marginBottom: "8px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#666666",
                  margin: "auto 0px",
                }}
              >
                Filters
              </Typography>
              {(chart.account || channel.isBlend) && <Typography
                style={{ color: "#0869FB", margin: "auto 0px", cursor: "pointer" }}
                onClick={() => {
                  setFilterListOpen(true);
                }}
              >
                <FilterIcon /> See All
              </Typography>}
            </div>
            {filterString ?
              (<div
                style={{
                  marginTop: "15px",
                  marginBottom: "5px",
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div
                  className="filter-list"
                  onClick={() => !shared && setIsFilterOpen(true)}
                  style={{ cursor: !shared && "pointer" }}
                >
                  {filterString}
                </div>
                <IconButton
                  onClick={() => {
                    updateFilter(Filter.new(chart));
                  }}
                  disableRipple
                  disableFocusRipple
                  aria-label="delete"
                >
                  <Clear />
                </IconButton>
              </div>) : (
                <FilterDrop setCurrentFilter={setCurrentFilter} updateFilter={updateFilter} chart={chart} channel={channel} setIsFilterOpen={setIsFilterOpen} />
              )

            }

          </>
        </AccordionDetails>
      </Accordion>

      {isFilterOpen && (
        <FilterPopup
          accountCols={accountCols}
          blendChannelCols={channelCols}
          chart={chart}
          onClose={() => setIsFilterOpen(false)}
          currentFilter={currentFilter ?? chartFilter}
          chartFilter={chartFilter}
          onChange={(filter) => {
            updateFilter(filter);
            handleAllChartsForceReload(filter.id);
          }}
        />
      )}

      <FilterListModal
        modal={filterListOpen}
        setModal={setFilterListOpen}
        chart={chart}
        chartFilter={chartFilter}
        onChange={(filter) => {
          updateFilter(filter);
          setFilterListOpen(false);
        }}
        handleAllChartsForceReload={handleAllChartsForceReload}
        blendChannelCols={channelCols}
      />
      <DateRangeModal
        compare={true}
        dateRange={dashboardDateRange}
        compareDateRange={dateRange}
        changeCompareDate={applyDate}
        isDatePickerOpen={isDatePickerOpen}
        setIsDatePickerOpen={setIsDatePickerOpen}
      />
    </div>
  );
}