import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  TextField,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { Clear, ExpandMore } from "@mui/icons-material";
import MetricList from "./MetricList";
import SingleMetricSettings from "./SingleMetricSettings";
import { Metric } from "../../models/chart";
import { useSelector } from "react-redux";
import { GetChannel } from "../../models/channel";
import { useDispatch } from "react-redux";
import { checkDate } from "../Charts/ChartUtils";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

const filterDateDimensions = (dimensions = [], chartType) => {
  if (chartType === "LINE" || chartType === "LINEBAR" || chartType === "AREA") {
    return dimensions.filter((dim) => checkDate(dim));
  } else {
    return dimensions;
  }
};

export default function EditChartType1({
  handleAllChartsForceReload,
  editDrawerState,
  currentMetricState,
  setCurrentMetricState,
  channel,
  accountCols,
  channelCols,
}) {
  const dispatch = useDispatch();
  const { chart, handleChartChange } = editDrawerState;
  const dateDimension = [
    GetChannel(chart.channelType)?.properties?.dateDimension,
  ].filter((d) => d);
  const [leftMetrics, setLeftMetrics] = React.useState(
    chart.leftMetrics.concat([null])
  );
  const [rightMetrics, setRightMetrics] = React.useState(
    chart.rightMetrics?.concat([null])
  );
  const [chartDimensions, setChartDimensions] = React.useState(
    !chart.dimensions?.length ? [null] : chart.dimensions
  );
  const [table, setTable] = React.useState(chart.table);
  const [dataLevel, setDataLevel] = React.useState({
    id: chart.dataLevel,
    name: chart.dataLevel,
  });
  const state = useSelector((state) => channel.getState(state));
  const tables = state.tables;
  const dataLevels = (state.dataLevels?.[table?.id] ?? []).map((level) => ({
    id: level,
    name: level,
  }));
  const baseMetrics = channel.isBlend
    ? channelCols.metrics
    : Array.isArray(state.metrics)
      ? state.metrics
      : state.metrics?.[table?.id + (dataLevel?.id ? `:${dataLevel.id}` : "")] ??
      [];
  const baseDimensions = Array.isArray(state.dimensions)
    ? filterDateDimensions(
      channel.isBlend ? channelCols.dimensions : state.dimensions,
      chart.type
    )
    : filterDateDimensions(
      state.dimensions?.[
      table?.id + (dataLevel?.id ? `:${dataLevel.id}` : "")
      ],
      chart.type
    ) ?? [];

  const customMetrics = accountCols
    ? accountCols.metrics
    : Array.isArray(state.customMetrics)
      ? state.customMetrics
      : state.customMetrics?.[
      table?.id + (dataLevel?.id ? `:${dataLevel.id}` : "")
      ] ?? [];
  const customDimensions = accountCols
    ? filterDateDimensions(accountCols.dimensions, chart.type)
    : Array.isArray(state.customDimensions)
      ? filterDateDimensions(state.customDimensions, chart.type)
      : filterDateDimensions(
        state.customDimensions?.[
        table?.id + (dataLevel?.id ? `:${dataLevel.id}` : "")
        ],
        chart.type
      ) ?? [];

  const metrics = baseMetrics.concat(customMetrics);
  const dimensions = baseDimensions.concat(customDimensions);
  const [compareWith, setCompareWith] = React.useState(chart.compareWith);

  if (!currentMetricState.metric.channelType) {
    currentMetricState.metric.channelType = channel.type;
    currentMetricState.metric.validate();
  }

  React.useEffect(() => {
    if (table !== chart.table) {
      handleChartChange("table", table);
      {
        channel.type === "tiktok-ads" &&
          setDataLevel(dataLevels[table?.id]?.[0] ?? { id: "", name: "" });
      }
      setLeftMetrics([null]);
      setRightMetrics(chart.rightMetrics ? [null] : null);
      setChartDimensions([null]);
      // if (!metrics.length) {
      //   dispatch(channel.actions?.getColumns(table));
      // }
    }
  }, [table]);

  React.useEffect(() => {
    if (
      (chart.type === "LINE" ||
        chart.type === "LINEBAR" ||
        chart.type === "AREA") &&
      !checkDate(chartDimensions[0])
    ) {
      if (
        dateDimension?.length === 1 &&
        dimensions.find((dim) => dim.id === dateDimension[0].id)
      ) {
        setChartDimensions(dateDimension);
      } else if (dimensions.length) {
        setChartDimensions([dimensions[0]]);
      }
    }
  }, [chart.type]);

  React.useEffect(() => {
    if (!checkDate(chartDimensions?.[0])) {
      handleChartChange("compareWith", "");
      setCompareWith("");
    }
    handleChartChange(
      "dimensions",
      chartDimensions.filter((dimension) => dimension && dimension.id)
    );
  }, [chartDimensions]);

  React.useEffect(() => {
    handleChartChange(
      "leftMetrics",
      leftMetrics.filter((metric) => metric && metric.metric)
    );
  }, [leftMetrics]);

  React.useEffect(() => {
    handleChartChange(
      "rightMetrics",
      rightMetrics?.filter((metric) => metric && metric.metric)
    );
  }, [rightMetrics]);

  const handleSingleMetricSettings = (metric, axis, index) =>
    setCurrentMetricState({
      isOpen: true,
      metric: metric,
      axis: axis,
      index: index,
    });

  const updateFilter = (filter) => {
    if (currentMetricState.axis === "left") {
      const newMetrics = Array.from(leftMetrics);
      const metric = Metric.copy(newMetrics[currentMetricState.index]);
      metric.filter = filter;
      newMetrics[currentMetricState.index] = metric;
      setLeftMetrics(newMetrics);
      setCurrentMetricState({ ...currentMetricState, metric: metric });
    } else {
      const newMetrics = Array.from(rightMetrics);
      const metric = Metric.copy(newMetrics[currentMetricState.index]);
      metric.filter = filter;
      newMetrics[currentMetricState.index] = metric;
      setRightMetrics(newMetrics);
      setCurrentMetricState({ ...currentMetricState, metric: metric });
    }
  };

  const getDisplayFilters = () => {
    return leftMetrics
      .filter((metric) => metric && metric.metric)
      .map((metric, index) => {
        const filterString = metric.getFilterString();
        if (filterString) {
          return {
            index: index,
            axis: "left",
            string: filterString,
          };
        }
        return null;
      })
      .concat(
        rightMetrics
          ?.filter((metric) => metric && metric.metric)
          .map((metric, index) => {
            const filterString = metric.getFilterString();
            if (filterString) {
              return {
                index: index,
                axis: "right",
                string: filterString,
              };
            }
            return null;
          })
      )
      .filter((filter) => filter);
  };

  React.useEffect(() => {
    if (chart.dataLevel !== dataLevel.id) {
      handleChartChange("dataLevel", dataLevel?.id);
      setLeftMetrics([null]);
      setRightMetrics(chart.rightMetrics ? [null] : null);
      setChartDimensions([null]);
    }
  }, [dataLevel]);

  React.useEffect(() => {
    // setLeftMetrics(leftMetrics.filter(metric => !metric || metrics?.findIndex(m => m.id === metric?.metric?.id) > -1));
    // setRightMetrics(rightMetrics?.filter(metric => !metric || metrics?.findIndex(m => m.id === metric?.metric?.id) > -1));
    // setChartDimensions(chartDimensions.filter(dim => !dim || dimensions?.findIndex(d => d.id === dim?.id) > -1));
  }, [accountCols])

  const onDragEndLeftMetrics = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    destination.index =
      destination.index === leftMetrics.length - 1
        ? destination.index - 1
        : destination.index;
    if (destination.index === source.index) return;
    const newMetrics = Array.from(leftMetrics);
    const pop = newMetrics.splice(source.index, 1)[0];
    newMetrics.splice(destination.index, 0, pop);
    setLeftMetrics(newMetrics);
  };

  const onDragEndRightMetrics = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    destination.index =
      destination.index === rightMetrics.length - 1
        ? destination.index - 1
        : destination.index;
    if (destination.index === source.index) return;
    const newMetrics = Array.from(rightMetrics);
    const pop = newMetrics.splice(source.index, 1)[0];
    newMetrics.splice(destination.index, 0, pop);
    setRightMetrics(newMetrics);
  };

  const displayFilters = getDisplayFilters();

  return (
    <div>
      {currentMetricState.isOpen ? (
        <SingleMetricSettings
          chart={chart}
          channel={channel}
          accountCols={accountCols}
          channelCols={channelCols}
          currentMetric={currentMetricState.metric}
          isKPI={chart.type === "KPI"}
          handleChartChange={handleChartChange}
          dimension={chart.dimensions ? chart.dimensions[0]?.name : null}
          onChange={(metric) => {
            if (currentMetricState.axis === "left") {
              const newMetrics = Array.from(leftMetrics);
              newMetrics[currentMetricState.index] = metric;
              if (
                metric &&
                currentMetricState.index === newMetrics.length - 1
              ) {
                newMetrics.push(null);
              }
              setLeftMetrics(newMetrics);
            } else {
              const newMetrics = Array.from(rightMetrics);
              newMetrics[currentMetricState.index] = metric;
              if (
                metric &&
                currentMetricState.index === newMetrics.length - 1
              ) {
                newMetrics.push(null);
              }
              setRightMetrics(newMetrics);
            }
            setCurrentMetricState({ ...currentMetricState, metric: metric });
          }}
          updateFilter={updateFilter}
          handleAllChartsForceReload={handleAllChartsForceReload}
          onClose={() =>
            setCurrentMetricState({ ...currentMetricState, isOpen: false })
          }
        />
      ) : (
        <div>
          <Accordion
            defaultExpanded={chart.title === ""}
            sx={{
              boxShadow: "none",
              "& .Mui-expanded": { marginBottom: "0px" },
              width: "100%",
            }}
          >
            <AccordionSummary
              sx={{ maxHeight: "30px", width: "100%", padding: "0px" }}
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#000000",
                }}
              >
                Title
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0px 0px" }}>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #EAEAEC",
                  },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: "1px solid #EAEAEC",
                  },
                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    border: "1px solid #EAEAEC",
                  },
                }}
                InputProps={{
                  sx: {
                    fontSize: "14px",
                    fontFamily: "Inter",
                    fontWeight: "500",
                    height: "40px"
                  },
                }}
                placeholder="Add Title"
                style={{ width: "100%" }}
                id="outlined-basic"
                defaultValue={chart.title}
                onChange={(e) => handleChartChange("title", e.target.value)}
                variant="outlined"
                size="small"
              />
            </AccordionDetails>
          </Accordion>
                
                {/* Removing of Comparison from Widget */}
          {/* {checkDate(chartDimensions?.[0]) && (
            <Accordion
              sx={{
                boxShadow: "none",
                '&:before': {
                  display: 'none',
                },
                width: "100%",
              }}
              disableGutters
              elevation={0}
            >
              <AccordionSummary
                sx={{ maxHeight: "30px", width: "100%", padding: "0px" }}
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#000000",
                  }}
                >
                  Compare with
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: "10px 0px" }}>
                <Select
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #EAEAEC",
                    },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: "1px solid #EAEAEC",
                    },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      border: "1px solid #EAEAEC",
                    },
                  }}
                  style={{ width: "100%" }}
                  variant="outlined"
                  size="small"
                  value={compareWith}
                  onChange={(e) => {
                    setCompareWith(e.target.value);
                    handleChartChange("compareWith", e.target.value);
                  }}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>No Comparison</em>
                  </MenuItem>
                  <MenuItem value="day">Day</MenuItem>
                  <MenuItem value="week">Week</MenuItem>
                  <MenuItem value="months">Month</MenuItem>
                </Select>
              </AccordionDetails>
            </Accordion>
          )} */}

          <Accordion
            defaultExpanded={true}
            sx={{
              boxShadow: "none",
              width: "100%",
              '&:before': {
                display: 'none',
              }
            }}
            disableGutters
            elevation={0}
            id="editWigetScroll"
          >
            <AccordionSummary
              sx={{ maxHeight: "30px", width: "100%", padding: "0px" }}
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#000000",
                }}
              >
                Widget Data
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: "0px",
                paddingBottom: "20px",
              }}
            >
              {/* report type */}
              {tables && (
                <div>
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#000000",
                    }}
                  >
                    Report type
                  </Typography>
                  <MetricList
                    channel={channel}
                    metric={table}
                    metrics={tables}
                    isEditable={true}
                    onClick={setTable}
                    defaultTitle="Add Report Type"
                  />
                  <br />
                </div>
              )}

              {tables && channel.type === "tiktok-ads" && (
                <div>
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#000000",
                    }}
                  >
                    Data level
                  </Typography>
                  <MetricList
                    channel={channel}
                    metric={dataLevel}
                    metrics={dataLevels}
                    isEditable={true}
                    onClick={setDataLevel}
                    defaultTitle="Add Data Level"
                  />
                  <br />
                </div>
              )}

              {/* dimensions */}

              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#666666",
                  marginTop: "10px",
                }}
              >
                Dimension
              </Typography>
              {chartDimensions.map((dimension, index) => (
                <MetricList
                  key={chartDimensions[0]?.id}
                  channel={channel}
                  metric={dimension}
                  metrics={dimensions}
                  isEditable={true}
                  defaultTitle="Add New Dimension"
                  onClick={(dimension) => {
                    const newDimensions = Array.from(chartDimensions);
                    newDimensions[index] = dimension;
                    setChartDimensions(newDimensions);
                  }}
                />
              ))}

              {/* left metrics */}
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#666666",
                  marginTop: "25px",
                }}
              >
                {rightMetrics ? "LEFT AXIS" : "Metrics"}
              </Typography>
              <DragDropContext onDragEnd={onDragEndLeftMetrics}>
                <Droppable droppableId="1" direction="vertical">
                  {(droppableProvided) => (
                    <div
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                    >
                      {leftMetrics.map((metric, index) => {
                        return (
                          <Draggable
                            draggableId={`${index}`}
                            index={index}
                            disableInteractiveElementBlocking
                          >
                            {(draggableProvided) => (
                              <div
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "2px",
                                    // marginLeft: metric?.metric
                                    //   ? "-20px"
                                    //   : "0px",
                                  }}
                                >
                                  {/* {metric?.metric && (
                                    <div {...draggableProvided.dragHandleProps}>
                                      <DragIndicatorIcon
                                        style={{
                                          marginTop: "15px",
                                          width: "18px",
                                          color: "#000000AA",
                                        }}
                                      />
                                    </div>
                                  )} */}
                                  <MetricList
                                    key={leftMetrics.length * (index + 1)}
                                    channel={channel}
                                    metric={metric}
                                    value={metric}
                                    metrics={metrics}
                                    onClick={(metric) => {
                                      if (index === leftMetrics.length - 1) {
                                        const newMetrics =
                                          Array.from(leftMetrics);
                                        newMetrics[index] = Metric.new(
                                          chart,
                                          metric
                                        );
                                        newMetrics.push(null);
                                        setLeftMetrics(newMetrics);
                                      } else {
                                        handleSingleMetricSettings(
                                          metric,
                                          "left",
                                          index
                                        );
                                      }
                                    }}
                                    draggableProvided={draggableProvided}
                                    onDelete={() => {
                                      const newMetrics =
                                        Array.from(leftMetrics);
                                      setLeftMetrics(
                                        newMetrics
                                          .slice(0, index)
                                          .concat(newMetrics.slice(index + 1))
                                      );
                                    }}
                                    isColumnThere = {metric ? (metrics?.find(m => m.id === metric?.metric?.id) ?? false) : true}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {droppableProvided ? droppableProvided.placeholder : null}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              {/* right metrics */}
              {rightMetrics && (
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#666666",
                    marginTop: "25px",
                  }}
                >
                  RIGHT AXIS
                </Typography>
              )}
              <DragDropContext onDragEnd={onDragEndRightMetrics}>
                <Droppable droppableId="1" direction="vertical">
                  {(droppableProvided) => (
                    <div
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                    >
                      {rightMetrics?.map((metric, index) => {
                        return (
                          <Draggable
                            draggableId={`${index}`}
                            index={index}
                            disableInteractiveElementBlocking
                          >
                            {(draggableProvided) => (
                              <div
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "2px",
                                    // marginLeft: metric?.metric ? "20px" : "0px",
                                  }}
                                >
                                  {/* {metric?.metric && (
                                    <div {...draggableProvided.dragHandleProps}>
                                      <DragIndicatorIcon
                                        style={{
                                          marginTop: "15px",
                                          width: "18px",
                                          color: "#000000AA",
                                        }}
                                      />
                                    </div>
                                  )} */}
                                  <MetricList
                                    key={rightMetrics.length * (index + 1)}
                                    channel={channel}
                                    metric={metric}
                                    value={metric}
                                    metrics={metrics}
                                    onClick={(metric) => {
                                      if (index === rightMetrics.length - 1) {
                                        const newMetrics =
                                          Array.from(rightMetrics);
                                        newMetrics[index] = Metric.new(
                                          chart,
                                          metric
                                        );
                                        newMetrics.push(null);
                                        setRightMetrics(newMetrics);
                                      } else {
                                        handleSingleMetricSettings(
                                          metric,
                                          "right",
                                          index
                                        );
                                      }
                                    }}
                                    draggableProvided={draggableProvided}
                                    onDelete={() => {
                                      const newMetrics =
                                        Array.from(rightMetrics);
                                      setRightMetrics(
                                        newMetrics
                                          .slice(0, index)
                                          .concat(newMetrics.slice(index + 1))
                                      );
                                    }}
                                    isColumnThere = {metric ? (metrics?.find(m => m.id === metric?.metric?.id) ?? false) : true}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {droppableProvided ? droppableProvided.placeholder : null}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              {/* filter display */}
              {displayFilters.length > 0 && (
                <h4 className="inter bold"
                  style={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#666666",
                    marginTop: "25px"
                  }}
                >
                  Filters
                </h4>
              )}
              {displayFilters.map((filter, index) => (
                <div
                  key={index}
                  style={{
                    marginTop: "15px",
                    marginBottom: "5px",
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div className="filter-list" style={{ cursor: "pointer" }}>{filter.string}</div>
                  <IconButton
                    onClick={() => {
                      if (filter.axis === "left") {
                        const newMetrics = Array.from(leftMetrics);
                        newMetrics[filter.index] = Metric.new(
                          chart,
                          newMetrics[filter.index].metric
                        );
                        setLeftMetrics(newMetrics);
                      } else {
                        const newMetrics = Array.from(rightMetrics);
                        newMetrics[filter.index] = Metric.new(
                          chart,
                          newMetrics[filter.index].metric
                        );
                        setRightMetrics(newMetrics);
                      }
                    }}
                    disableRipple
                    disableFocusRipple
                    aria-label="delete"
                  >
                    <Clear />
                  </IconButton>
                </div>
              ))}
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </div>
  );
}