import { all, put, select, takeLatest } from "redux-saga/effects"
import {
    CHECK_AUTH, CHECK_AUTH_FAILURE, CHECK_AUTH_SUCCESS,
    GET_ALL_CUSTOM_COLUMNS, GET_ALL_COLUMNS_SUCCESS, GET_ALL_COLUMNS_FAILURE,
    CREATE_COLUMN, CREATE_COLUMN_SUCCESS, CREATE_COLUMN_FAILURE,
    UPDATE_COLUMN, UPDATE_COLUMN_SUCCESS, UPDATE_COLUMN_FAILURE,
    DELETE_COLUMN, DELETE_COLUMN_SUCCESS, DELETE_COLUMN_FAILURE,
    GET_ALL_CUSTOM_DIMENSIONS, GET_ALL_DIMENSIONS_SUCCESS,
    SAVE_DIMENSION, SAVE_DIMENSION_SUCCESS, SAVE_DIMENSION_FAILURE,
    DELETE_DIMENSION, DELETE_DIMENSION_SUCCESS, DELETE_DIMENSION_FAILURE, GET_ALL_DIMENSIONS_FAILURE,
} from "./type";
import { api } from ".."
import { ChannelType } from "../../models/channel";
import { UPDATE_LOADER } from "../user/type";
import { EVENTS, handleMixPanelData } from "../../utils/mixpanelV2";

function* checkAuth() {
    try {
        const authAccs = yield select(state => state.channel.authenticatedAccounts)
        if (!authAccs.length) {
            yield put({
                type: UPDATE_LOADER,
                payload: true
            })
        }
        const response = yield api.post("/v2/analytics/channel/authenticated");
        yield put({
            type: CHECK_AUTH_SUCCESS,
            payload: response.data
        });
        yield put({
            type: UPDATE_LOADER,
            payload: false
        })
    } catch (error) {
        if (error.code === "ERR_NETWORK") {
            yield put({
                type: "SERVICE_DOWN"
            });
        } else {
            yield put({
                type: CHECK_AUTH_FAILURE
            });
        }
    }
}

export const getAccountBasedColumns = (selectedAccount, channel) => new Promise(async (resolve, reject) => {
    try {
        let response = {};
        switch (channel.type) {
            case ChannelType.FacebookAds:
                response = await api.post(`/v2/analytics/fb/get-columns`,
                    { accountId: selectedAccount.accountId, columnTypes: ["CUSTOM"] }
                );
                break;
            case ChannelType.GoogleAnalytics:
                response = await api.post("/v2/analytics/ga/get-dimensions-metrics", {
                    account_id: selectedAccount.accountId,
                    web_property_id: selectedAccount.webPropertyId,
                    view_id: selectedAccount.viewId,
                    customOnly: true
                });
                break;
            case ChannelType.GoogleAnalytics4:
                response = await api.post("/v2/analytics/ga4/get-dimensions-metrics", {
                    adAccountId: selectedAccount.accountId,
                    propertyId: selectedAccount.webPropertyId,
                    customOnly: true
                });
                break;
            case ChannelType.Adjust:
                response = await api.post("/v2/analytics/adjust/get-columns", {
                    accountId: selectedAccount.accountId,
                    columnTypes: ["EVENTS_WITHOUT_COHORT"]
                });
                break;
            default:
                break;
        }
        let customColResponse = await api.post(`/v2/analytics/customized-columns/get/all`,
            { audiences: [{ type: "AD_ACCOUNT", id: selectedAccount.id, dataSource: channel.type }] }
        );
        let customCols = customColResponse.data?.data.map(column => ({
            id: column.id,
            name: column.name,
            type: "METRIC",
            dataType: column.format ?? "FLOAT",
            metricType: column.other.metricType?.id,
            group: "Customized",
            formula: column.other.nameFormula,
            account: column.audiences[0].metaData,
            // dataSource: column.source                        // issue in identifying difference in blend and standard dataSource metrics
        })) ?? []
        resolve({ columns: (response.data?.columns ?? []).concat(customCols) });
    } catch (error) {
        reject(error.response);
    }
});

export const getChartCurrency = (account, channel) => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post(`/v2/analytics/${channel.type === ChannelType.FacebookAds ? "fb" : channel.type === ChannelType.GoogleAnalytics ? "ga" : channel.type === ChannelType.GoogleAnalytics4 ? "ga4" : channel.type}/get-currency`,
            channel.type === ChannelType.FacebookAds ? { account_id: account.id } : channel.transformer.getAccountId(account)
        );
        resolve(response.data);
    } catch (error) {
        reject(error.response);
    }
});

export const getWidgetData = (chart, apiConfigs) => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post(`/v2/analytics/channel/${apiConfigs[0]?.dataSource}/get-widget-data`, {
            chart,
            api_configs: JSON.stringify(apiConfigs),
        });
        resolve(response.data.responses);
    } catch (error) {
        reject(error.response);
    }
});

function* getAllCustomDimensions(args) {
    try {
        const response = yield api.post("/v2/analytics/customized-dimension/get/all");
        const customDimensions = response.data.data;
        yield put({
            type: GET_ALL_DIMENSIONS_SUCCESS,
            payload: customDimensions
        });
    } catch (error) {
        yield put({
            type: GET_ALL_DIMENSIONS_FAILURE
        });
    }
    if (args?.payload?.update) {
        yield args.payload.update()
    }
}

export const fetchMapping = (args) => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post("/v2/analytics/customized-dimension/proceed", args);
        resolve({ ...response.data.message, refresh: response.data.refresh });
    } catch (error) {
        reject(error);
    }
});

export const refreshMapping = (args) => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post("/v2/analytics/customized-dimension/mappings/refresh", args);
        resolve(response.data.message);
    } catch (error) {
        reject(error);
    }
});

function* saveDimension(args) {
    try {
        yield api.post("/v2/analytics/customized-dimension/update", args.payload.mappingObj);
        yield put({
            type: SAVE_DIMENSION_SUCCESS,
            payload: args.payload.mappingObj
        });
        yield getAllCustomDimensions();
        yield args.payload.loading("Success");
    } catch (error) {
        yield put({
            type: SAVE_DIMENSION_FAILURE
        });
        yield args.payload.loading("Fail");
    }
}

function* deleteDimension(args) {
    try {
        const id = args.payload.id;
        yield api.post(`/v2/analytics/customized-dimension/delete/${id}`);
        yield put({
            type: DELETE_DIMENSION_SUCCESS,
            payload: { id: id }
        });
        yield getAllCustomDimensions();
        yield args.payload.loading("Success");
    } catch (error) {
        yield put({
            type: DELETE_DIMENSION_FAILURE
        });
        yield args.payload.loading("Fail");
    }
}

function* getAllCustomColumns(args) {
    try {
        const response = yield api.post("/v2/analytics/customized-columns/get/all");
        const customColumns = response.data.data;
        yield put({
            type: GET_ALL_COLUMNS_SUCCESS,
            payload: customColumns
        });
    } catch (error) {
        yield put({
            type: GET_ALL_COLUMNS_FAILURE
        });
    }
    if (args?.payload?.update) {
        yield args.payload.update()
    }
}

function* createColumn(args) {
    try {
        const column = args.payload.columnData;
        const response = yield api.post("/v2/analytics/customized-columns/create", column);
        const id = response.data.id;
        yield put({
            type: CREATE_COLUMN_SUCCESS,
            payload: {
                id: id,
                column: column,
            }
        });
        yield getAllCustomColumns();
        yield args.payload.loading("Success");
        handleMixPanelData(EVENTS.success_save_custom_metric, column);
    } catch (error) {
        yield put({
            type: CREATE_COLUMN_FAILURE
        });
        yield args.payload.loading("Fail", error?.response?.data?.message);
    }
}

function* updateColumn(args) {
    try {
        const { audiences, ...restParams } = args.payload.params || {};
        yield api.post(`/v2/analytics/customized-columns/update/${args.payload.columnId}`, {
            params: restParams, audiences
        });
        yield put({
            type: UPDATE_COLUMN_SUCCESS,
            payload: args.payload
        });
        yield getAllCustomColumns();
        yield args.payload.loading("Success");
    } catch (error) {
        yield put({
            type: UPDATE_COLUMN_FAILURE
        });
        yield args.payload.loading("Fail", error?.response?.data?.message);
    }
}

function* deleteColumn(args) {
    try {
        const id = args.payload.id;
        yield api.post(`/v2/analytics/customized-columns/delete/${id}`);
        yield put({
            type: DELETE_COLUMN_SUCCESS,
            payload: { id: id }
        });
        yield getAllCustomColumns();
        yield args.payload.loading("Success");
    } catch (error) {
        yield put({
            type: DELETE_COLUMN_FAILURE
        });
        yield args.payload.loading("Fail");
    }
}

export default function* root() {
    yield all([
        takeLatest(CHECK_AUTH, checkAuth),
        takeLatest(GET_ALL_CUSTOM_DIMENSIONS, getAllCustomDimensions),
        takeLatest(SAVE_DIMENSION, saveDimension),
        takeLatest(DELETE_DIMENSION, deleteDimension),
        takeLatest(GET_ALL_CUSTOM_COLUMNS, getAllCustomColumns),
        takeLatest(CREATE_COLUMN, createColumn),
        takeLatest(UPDATE_COLUMN, updateColumn),
        takeLatest(DELETE_COLUMN, deleteColumn)
    ])
}
