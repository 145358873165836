import { Box, Typography, } from "@mui/material";
import React from "react";
import InputSelect from "../../Common/InputSelect";

export default function SegmentBox({ channel, segments, segmentRef }) {
  const [segment, setSegment] = React.useState(segmentRef.current);

  React.useEffect(() => {
    segmentRef.current = segment;
  }, [segment]);

  const isArray = segments[0].values;

  return (
    <Box
      sx={{
        marginTop: "20px",
        padding: "16px 24px",
        borderRadius: "16px",
        background: "#F6F7F8",
      }}>
      <p className="h18">{channel.properties.segmentBoxTitle ?? "Segments"}</p>
      {isArray ?
        <div style={{ display: "flex", gap: "10px" }}>
          {segments.map((s, index) =>
            <div style={{ width: "33%" }}>
              <Typography style={{ fontSize: "14px", marginBottom: "8px" }}>{s.name}</Typography>
              <InputSelect
                key={index}
                options={s.values}
                value={s.name !== "SKad Network API" ? s.values.find(seg => seg?.id === segment[index]?.id) : segment[index]}
                multiple={s.name === "SKad Network API"}
                title={""}
                onChange={(event, newValue) => {
                  const newSegments = Array.from(segment);
                  newSegments[index] = newValue;
                  setSegment(newSegments);
                }}
              />
            </div>
          )}
        </div>
        : <div style={{ width: "33%" }}>
          <InputSelect options={segments} value={segments.find(seg => seg?.id === segment?.id)} onChange={(event, newValue) => setSegment(newValue)} />
        </div>
      }
    </Box>
  );
}
